import React from 'react'
import { css, Text } from 'theme-ui'
import { PostShare } from '@widgets/Post'
import { ADHorizontal } from '@components/Ad'

const ShareImage = props => (
  <div css={css(t => t.styles.shareImage)}>
    <img {...props} css={css(t => t.styles.shareImageImage)}/>
    <Text css={css(t => t.styles.shareImageTitle)}> { props.title } </Text>
    <ADHorizontal />
  </div>
)

export default ShareImage
