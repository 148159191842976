import React from 'react'
import { Container, Box, Text } from 'theme-ui'

const styles = {
  container: {
    position: `relative`,
    zIndex: 10
  },
  title: {
    fontSize: 2
  },
  ad: {
    width: `160px`,
    height: `600px`,
    border: `0px`,
    padding: `0`,
    overflow: `hidden`,
    backgroundColor: `transparent`
  },
}

const ADVertical = ({ props }) => {
  return (
    <Box sx={styles.wrapper}>
      <Text sx={styles.title}>ADVERTISEMENT</Text>
      <iframe data-aa='2249513' src='//ad.a-ads.com/2249513?size=160x600' css={styles.ad}></iframe>
    </Box>
  )
}

export default ADVertical

ADVertical.defaultProps = {}
ADVertical.propTypes = {}
