import React from 'react'
import { Link as GLink } from 'gatsby'
import { Link, Text, Heading } from 'theme-ui'
import TextList from '@components/TextList'
import PageTitle from '@components/PageTitle'

const styles = {
  item: {
    display: `inline-block`
  }
}

export const PostHead = ({ title, author, date, timeToRead, category }) => {
  const info = (
    <div>
    <TextList>
    {category && category.slug && (
      <Text sx={{ ...styles.item, color: `alpha` }}>
        <Link as={GLink} to={category.slug}>
          <strong>{category.name}</strong>
        </Link>
      </Text>
    )}
    </TextList>
    <Heading variant='h2' as='h1'>
      {title}
    </Heading>
    <TextList>
      {author && author.slug && (
        <Text sx={styles.item}>
          <Link variant='mute' as={GLink} to={author.slug}>
            <strong>{author.name}</strong>
          </Link>
        </Text>
      )}
      {date && <Text sx={styles.item}>{date}</Text>}
      {/*timeToRead && (
        <Text sx={{ ...styles.item, color: `error` }}>
          <strong>{timeToRead} min read</strong>
        </Text>
      )*/}
    </TextList>
    </div>
  )

  return info;
}
