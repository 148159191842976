import React from 'react'
import { Container, Box, Text } from 'theme-ui'

const styles = {
  container: {
    position: `relative`,
    marginTop: `12px`,
    marginBottom: `24px`,
    paddingLeft: `0px !important`,
    zIndex: 10
  },
  title: {
    fontSize: 2
  },
  ad: {
    width: `320px`,
    height: `100px`,
    border: `0px`,
    padding: `0`,
    overflow: `hidden`,
    backgroundColor: `transparent`
  },
}

const ADHorizontal = ({ props }) => {
  return (
    <Container variant='compact' css={styles.container}>
      <Text sx={styles.title}>ADVERTISEMENT</Text>
      <iframe data-aa='2249516' src='//ad.a-ads.com/2249516?size=320x100' css={styles.ad}></iframe>
    </Container>
  )
}

export default ADHorizontal

ADHorizontal.defaultProps = {}
ADHorizontal.propTypes = {}
