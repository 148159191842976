import React from 'react'
import { Card, Text, Box, Flex } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import { ADHorizontal, ADVertical } from '@components/Ad'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTags,
  PostShare,
  PostFooter
} from '@widgets/Post'
import { css } from 'theme-ui'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props

  const styles = {
    flex: {
      flexWrap: `wrap`,
      justifyContent: `space-between`
    },
    item: {
      flexBasis: `100%`,
    },
  }

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider space={2}/>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Box>
          <Flex sx={styles.flex}>
            <Box sx={styles.item}>
              <PostHead {...post} />
            </Box>
          </Flex>
        </Box>
      </Stack>
      <Divider space={2}/>
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
            {/*<PostImage {...post} inCard />*/}
            <PostBody {...post} />
            <PostShare {...props} />
            <Divider space={2}/>
            <ADHorizontal />
            <Divider space={1}/>
            <PostTags {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            {/* <PostFooter {...{ previous, next }} /> */}
          <Divider space={3}/>
          {post.category && (
              <CardList
                title='More interesting stuff!'
                nodes={relatedPosts}
                variant='horizontal-aside'
                limit={6}
                omitMedia
                omitCategory
                distinct
                aside
              />
          )}
        </Main>
        <Sidebar>
          <Sticky>
              <ADVertical />
          </Sticky>
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Post
